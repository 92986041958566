import React from "react";

interface RegLinkDto {
    link: string;
    tg_link: string;
}

export default function (props: RegLinkDto) {
    return (props.link || props.tg_link)
        ?
        <>
            {props.link && (
                <div style={{
                    margin: '30px 0'
                }}>
                    <button type="button"
                            className="t-submit"
                            style={{color: "#ffffff", backgroundColor: "#000000", marginBottom: 10}}
                            onClick={() => {
                                window.open(props.link, '_blank');
                            }}
                    >
                        Регистрация через Wallet
                    </button>
                    {!props.tg_link && (
                        <div className="t706__cartwin-top" style={{
                            fontSize: 14,
                            fontWeight: 400,
                            textAlign: 'center',
                            color: '#000',
                            fontFamily: 'Manrope'
                        }}>Для того, чтобы зарегистрироваться в нашей программе лояльности, перейдите по кнопке выше.
                        </div>
                    )}
                </div>
            )}

            {props.tg_link && (
                <div style={{
                    margin: '30px 0'
                }}>
                    <button type="button"
                            className="t-submit"
                            style={{color: "#ffffff", backgroundColor: "#000000", marginBottom: 10}}
                            onClick={() => {
                                window.open(props.tg_link, '_blank');
                            }}
                    >
                        Регистрация через Telegram
                    </button>
                    <div className="t706__cartwin-top" style={{
                        fontSize: 14,
                        fontWeight: 400,
                        textAlign: 'center',
                        color: '#000',
                        fontFamily: 'Manrope'
                    }}>Для того, чтобы зарегистрироваться в нашей программе лояльности, перейдите по кнопке выше.
                    </div>
                </div>
            )}
        </>
        : <div>Произошла ошибка при загрузке программы лояльности. Пожалуйста, отключите VPN, перепроверьте
            интернет-соединение и обновите страницу. При повторной ошибке свяжитесь с администрацией сайта.</div>
}
