import React from "react";

export default function ErrorBlock(props: { errorText: string }) {
  return <div className="t-form__errorbox-middle">
    <div className="js-errorbox-all t-form__errorbox-wrapper" style={ { display: 'block' } }>
      <div className="t-form__errorbox-text t-text t-text_md">
        <p className="t-form__errorbox-item js-rule-error js-rule-error-req"
           style={ { display: 'block' } }>{ props.errorText }</p>
      </div>
    </div>
  </div>
}