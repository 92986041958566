import React, { useState } from "react";

export default function Verify(props: {
  typeSendCode: string
  setCode: (arg0: number) => void
}) {

  const [code, setCode] = useState(0);

  return <div className="t-input-group t-input-group_ph">
    <label className='t-input-title t-descr t-descr_md'>
      { props.typeSendCode === 'CALL' && 'Вам поступит звонок. Введите последние 4 цифры номера телефона' }
      { props.typeSendCode === 'SMS' && 'Введите код из СМС' }
      { props.typeSendCode === 'CARD' && 'Введите код подтверждения' }
    </label>
    <div className="t-input-block">
      <input className="t-input"
             style={ { color: '#000000', border: '1px solid #000000' } }
             onChange={ (e) => {
               // @ts-ignore
               setCode(e.target.value)
             } }/>
    </div>
    <br/>
    <div className="t-input-block">
      <button type="button"
              className="t-submit"
              style={ { color: "#ffffff", backgroundColor: "#000000" } }
              onClick={ () => {
                props.setCode(code)
              } }
      >
        { 'Ввести код' }
      </button>
    </div>
  </div>
}