import AsgkApi, {loyaltyTypeDto, PositionDto, User} from "./models/asgkApi";
import React, {useEffect, useMemo, useState} from "react";
import RegLink from "./components/RegLink";
import BonusCard from "./components/BonusCard";
import {AxiosError} from "axios";

export default function DiscountPage(props: { form: HTMLFormElement }) {
    const [phone, setPhone] = useState('');
    const [link, setLink] = useState('');
    const [tg_link, setTgLink] = useState('');
    const [user, setUser] = useState<User>();
    const [operation, setOperation] = useState<loyaltyTypeDto>();
    const [correctPaymentSystem, setCorrectPaymentSystem] = useState(false);
    const [observer, setObserver] = useState<MutationObserver>();

    const asgk = useMemo(() => new AsgkApi('Test'), []);

    useEffect(() => {
        const payments = document.getElementsByClassName('t-radio_payment')
        let cps = false

        Array.prototype.forEach.call(payments, function (payment) {
            if (payment.value === 'custom' && payment.checked === true) {
                cps = true
            }
            // @ts-ignore
            payment.addEventListener('click', function (e) {
                if (e.target.value === 'custom') {
                    getSearch();
                    setCorrectPaymentSystem(true)
                } else {
                    setCorrectPaymentSystem(false)
                }
            })
        })

        if (cps) {
            getSearch();
            setCorrectPaymentSystem(true)
        }

    }, [])

    useEffect(() => {
        let phoneInput: Element;
        phoneInput = props.form.querySelectorAll('input[type=tel]')[0]
        const phoneInputWithValue = props.form.querySelectorAll('input[name=Phone]')[0]
        const setPhoneFunction = (e: any) => {
            // @ts-ignore
            if (phoneInputWithValue && phoneInputWithValue.value) {
                // @ts-ignore
                setPhone(phoneInputWithValue.value.replace(/[^0-9]/g, ""));
            } else {
                // @ts-ignore
                setPhone(e.target.value.replace(/[^0-9]/g, "") ?? '')
            }
        }
        phoneInput.addEventListener('change', setPhoneFunction);
    }, [])

    useEffect(() => {
        const loadData = async () => {
            if (!user) {
                const {link, tg_link} = await asgk.cardDeliveryForm();
                setLink(link);
                setTgLink(tg_link);
            }
        }

        loadData();
    }, [asgk, user])

    useEffect(() => {
        const loadData = async () => {
            let found = false;
            if (phone.length > 9 && correctPaymentSystem) {
                await asgk.searchUsers(phone)
                    .then(users => {
                        if (users.status === 200 && users.user.length > 0) {
                            users.user.forEach(user => {
                                if (user.phone.substr(user.phone.length - 10) === phone.substr(phone.length - 10)) {
                                    found = true;
                                    setUser(user);
                                    checkAllowedBonuses(user)
                                }
                            })
                        } else {
                            found = false;
                        }
                    })
                    .catch(err => {
                        console.error(err.response.data);
                        found = false;
                    })
            } else {
                found = false;
            }

            if (!found) {
                setUser(undefined);
            }
        };
        loadData();
    }, [phone, asgk]);

    useEffect(() => {
        if (correctPaymentSystem) {
            checkAllowedBonuses(user)
        }
    }, [correctPaymentSystem])

    useEffect(() => {
        if (observer) {
            observer.disconnect()
        }

        //@ts-ignore
        const totalAmountWrapDiv: HTMLDivElement = document.getElementsByClassName('t706__cartwin-totalamount-wrap')[0]
        if (totalAmountWrapDiv && totalAmountWrapDiv.style.display === 'none') {
            totalAmountWrapDiv.style.display = 'block'
        }
        if (totalAmountWrapDiv !== undefined) {
            const totalAmountDiv = totalAmountWrapDiv.getElementsByClassName('t706__cartwin-totalamount')[0]
            // создаем новый экземпляр наблюдателя
            const observer = new MutationObserver(function (mutations) {
                mutations.forEach(() => {
                    checkAllowedBonuses(user)
                });
            });
            // создаем конфигурации для наблюдателя
            const config: MutationObserverInit = {childList: true, subtree: true};
            // запускаем механизм наблюдения
            if (totalAmountDiv.childNodes.length) {
                observer.observe(totalAmountDiv, config);
            }
            setObserver(observer)
        }

    }, [user])

    function getSearch() {
        const loadData = async () => {
            // @ts-ignore
            let phones = props.form.querySelectorAll('input[name=Phone]');

            if (!phones || phones.length === 0) {
                // @ts-ignore
                phones = props.form.querySelectorAll('input[name=phone]');
            }

            // @ts-ignore
            const phone = phones[0].value.replace(/[^0-9]/g, "");

            let found = false;
            if (phone.length > 9) {
                await asgk.searchUsers(phone)
                    .then(users => {
                        if (users.status === 200 && users.user.length > 0) {
                            users.user.forEach(user => {
                                if (user.phone.substr(user.phone.length - 10) === phone.substr(phone.length - 10)) {
                                    found = true;
                                    setUser(user);
                                    checkAllowedBonuses(user)
                                }
                            })
                        } else {
                            found = false;
                        }
                    })
                    .catch(err => {
                        console.error(err.response.data);
                        found = false;
                    })
            } else {
                found = false;
            }

            if (!found) {
                setUser(undefined);
            }
        };
        loadData();
    }

    async function checkAllowedBonuses(user?: User) {
        if (user === undefined) return;

        let summ = 0
        let summ_without_discount = 0
        const products: PositionDto[] = [];
        if (props.form !== undefined) {
            try {
                summ = Number(props.form
                    .getElementsByClassName('t706__cartwin-totalamount-wrap')[0]
                    .getElementsByClassName('t706__cartwin-totalamount')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                    .textContent?.replace(' ', '').replace(',', '.'))

            } catch (e) {
            }
        }

        if (summ === 0) {
            try {
                summ = Number(document
                    .getElementsByClassName('t706__cartwin-totalamount-wrap')[0]
                    .getElementsByClassName('t706__cartwin-totalamount')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                    .textContent?.replace(' ', '').replace(',', '.'))

            } catch (e) {
            }
        }

        try {
            summ_without_discount = Number(document
                .getElementsByClassName('t706__cartwin-bottom')[0]
                .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                .textContent?.replace(' ', '').replace(',', '.'))
        } catch (e) {
        }

        if (summ_without_discount === 0) {
            try {
                summ_without_discount = Number(document
                    .getElementsByClassName('t706__cartwin-totalamount-info_value')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                    .textContent?.replace(' ', '').replace(',', '.'))
            } catch (e) {
            }
        }

        if (summ_without_discount === 0) {
            try {
                summ_without_discount = Number(document
                    .getElementsByClassName('t706__cartwin-totalamount')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                    .textContent?.replace(' ', '').replace(',', '.'))
            } catch (e) {
            }
        }

        if (summ === 0 && summ_without_discount === 0) {
            setTimeout(() => {
                checkAllowedBonuses(user)
            }, 1000)
            return;
        }

        try {
            const productsDiv = document
                .getElementsByClassName('t706__cartwin-products')[0]
            if (productsDiv) {
                // @ts-ignore
                productsDiv.childNodes.forEach((product: Element) => {
                    let newProduct: PositionDto = {
                        name: '',
                        price: 0,
                        quantity: 0
                    }
                    newProduct.name = product.getElementsByClassName('t706__product-title')[0].getElementsByTagName('a')[0].innerHTML ?? ''
                    newProduct.price = Number(product.getElementsByClassName('t706__cartwin-prodamount-price')[0].innerHTML.replace(' ', '').replace(',', '.')) ?? 0
                    newProduct.quantity = Number(product.getElementsByClassName('t706__product-quantity')[0].innerHTML) ?? 0
                    products.push(newProduct)
                })
            }
        } catch (e) {
        }

        if (products.length === 0) {
            try {
                const productsDiv = document
                    .getElementsByClassName('t706__cartpage-products')[0]
                if (productsDiv) {
                    // @ts-ignore
                    productsDiv.childNodes.forEach((product: Element) => {
                        let newProduct: PositionDto = {
                            name: '',
                            price: 0,
                            quantity: 0
                        }
                        newProduct.name = product.getElementsByClassName('t706__product-title')[0].getElementsByTagName('a')[0].innerHTML ?? ''
                        newProduct.price = Number(product.getElementsByClassName('t706__cartwin-prodamount-price')[0].innerHTML.replace(' ', '').replace(',', '.')) ?? 0
                        newProduct.quantity = Number(product.getElementsByClassName('t706__product-quantity')[0].innerHTML) ?? 0
                        products.push(newProduct)
                    })
                }
            } catch (e) {
            }

        }

        try {
            if (summ_without_discount > 0) {
                const operation = await asgk.operation({
                    user_id: user.user_id,
                    cashier: "online",
                    operation_number: Date.now().toString(),
                    positions: products,
                    summ,
                    summ_without_discount
                })

                setOperation(operation)
                setUser(operation.user)
            }
        } catch (error) {
            if (error instanceof Error) {
                throw error
            } else if (error instanceof AxiosError) {
                if (!error && error['response'])
                    console.error(error['response']['data'])
            }
        }
    }

    return <div>
        {correctPaymentSystem &&
            <div className="t706__cartwin-top">
                <div className="t706__cartwin-heading t-name t-name_xl">Программа лояльности</div>
            </div>
        }
        {
            user && props.form
                ? <BonusCard
                    correctPaymentSystem={correctPaymentSystem}
                    user={user}
                    form={props.form}
                    asgk={asgk}
                    setUser={setUser}
                    observer={observer}
                    setObserver={setObserver}
                    operation={operation}
                />
                : (correctPaymentSystem ? <RegLink link={link} tg_link={tg_link}/> : <div/>)
        }
    </div>;
}
